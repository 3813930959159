/* src/App.css */
/* src/App.css or src/index.css */
:root {
  --nav-height: 60px; /* Adjust this value if your nav bar height changes */
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Ensure consistent sizing */
  overflow: hidden; /* Prevent scrolling on the main container */
}
.app-container {
  display: flex;
  flex-direction: column;
  background-color: black; /* Background color */
  position: relative; /* Allow absolutely positioned children */
  margin:0;
  overflow: hidden; /* Prevent scrolling on the main container */
  height: calc(var(--app-height));
  width: var(--app-width);
}

video {
  width: 100%; /* Full width for video */
  height: calc(var(--app-height)-52px);
  object-fit: cover; /* Cover the video area */
}

.header {
  position: absolute; /* Absolute positioning */
  top: 0;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-around;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent */
  z-index: 10; /* Ensure it overlays on top of video */
}

.login-button{
  background:transparent;
  color:#fff;
  font-weight: bold;
  border:0px;
}
.menu-button {
  flex: 1; /* Make each button take up equal space */
  display: flex; /* Ensure flexbox layout */
  flex-direction: column; /* Stack icon and text */
  align-items: center; /* Center them */
  justify-content: center; /* Center content vertically */
  background: none; /* No background */
  border: none; /* No border */
  color: white; /* Text color */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Smooth transition */
}

.menu-button.active{
  background-color: #151515; /* No background */
  transition: background-color 0.3s; /* Smooth transition */
}

.menu-button:hover {
  background-color: #ccc; /* Change background color on hover */
  color: black; /* Optionally change text color on hover */
}

/* Style the bottom nav */
.bottom-nav {
  position: relative;
  bottom: 0;
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: space-around; /* Space buttons evenly */
  height: 52px;
}
.feed {
  flex-grow: 1; /* Allow feed to take remaining space */
  overflow-y: auto; /* Allow vertical scrolling */
  padding: 10px;
}

.block-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(var(--app-height)-52px);
  width: 100%;
  overflow: hidden;
  background-color: black;
  color:white;
  touch-action: none; /* Prevent default scrolling */
}
.swipe-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}


/* chat */
.chat-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.chat-box {
  overflow-y: auto;
  padding: 10px;
  height: calc(100% - 50px);
  color: white;
}
.chat-message {
  margin-bottom: 5px;
}
.chat-input {
  display: flex;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px;
}
.chat-input input {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 5px;
}
.chat-input button {
  margin-left: 10px;
  padding: 10px;
  background-color: #007bff;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}
.chat-message 
{
  padding: 1px;
  margin: 1px 0;
  border-radius: 5px;
  color: #ffffffe3; 
}
.streamer-message {
	background-color: #ff000018;
	color: #ffffffe3;
	font-weight: bolder;
}



.chat-header {
  display: flex;
  align-items: center;
}

.user-image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}
.gift-count {
	margin-right: 10px;
	top: -10px;
	font-size: 10px;
	position: relative;
  background-color:#007bffab;
  border-radius: 5px;;
}
.host-tag {
	margin-right: 10px;
	top: -10px;
	font-size: 10px;
	position: relative;
  background-color:#272727ab;
  border-radius: 5px;
  color:white;
  font-size:10px;
}
.user-name {
	margin-right: 10px;
	top: -10px;
	font-size: 10px;
  color:#ccc;
	position: relative;
}

.chat-content {
  margin-left: 42px; /* Indent to align the message under the image and header */
  max-width: 100%; 
  word-wrap: break-word; 
  white-space: pre-wrap; 
  top:-20px;
  position:relative;
}

.streamer-message .user-name {
  color: gold; /* Highlight streamer messages */
  font-weight: bold;
}


.no-streams-container {
  position: relative;
  width: 100%;
  height: 100%; /* Same height as VideoPlayer */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.background-video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the entire container */
}

.overlay-text {
  position: absolute;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%);
  color: black;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  animation: fadeInOut 3s ease-in-out infinite; /* Add animation */
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
}



/* header */
.header {
  position: absolute;
  top: 20px; /* Lowered from the top */
  width: 100%;
  height: 50px;
  padding: 10px;
  display: flex;
  justify-content: space-between; /* Space between feeds and search */
  align-items: center; /* Center items vertically */
  background-color: rgba(0, 0, 0, 0.05); /* Semi-transparent */
}
.feeds-container {
  display: inline-block; /* Use flex for the feeds container */
  justify-content: center; /* Center feed buttons */
  flex-grow: 1; /* Allow it to grow and take available space */
}
.feed-button {
  color: white;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin: 0 5px; /* Reduced margin for closer spacing */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8); /* Black shadow */
}
.separator {
  color: white; /* Separator color */
  margin: 0 5px; /* Space around the separator */ 
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8); /* Black shadow */

}
.active {
  border-bottom: 2px solid white; /* Indicator for active feed */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8); /* Black shadow */

}
.search-button {
  color: white; /* Icon color */
  background: none; /* No background */
  border: none; /* No border */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 16px; /* Same font size as feed buttons */
  right:100px;
  position:relative;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8); /* Black shadow */
}


/* friends */
.friends-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color:#fff;
  background-color: blue; /* Optional background color */
  height: 100vh;
}


/* go live */
.go-live-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(var(--app-height)-52px);
  width: 100%;
  overflow: hidden;
  background-color: black;
  color:white;
  touch-action: none; /* Prevent default scrolling */
}
.StartLive {
	background-color: #ccc;
	border-radius: 10px;
	position: absolute;
	display: inline-block;
	width: 91%;
	padding: 14px;
  color:black;
}
.addHashtagsText {
	border-radius: 10px;
	height: 100px;
	margin: 0 auto;
	width: 100%;
	position: relative;
  resize: none;
}
.start-live-button {
  background-color: red;
  color: white;
  border-radius: 10px;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  width:100%;
  font-size: 18px;
  margin: 0 auto;
}
.popularHashtag
{
  background-color:#ccc;
  border-radius: 10px;
  color : blue;
  padding:2px;
}
.liveSmallText
{
  font-size:10px;
}
.stop-stream-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.7);
}

.stop-circle {
  width: 35px;
  height: 35px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stop-square {
  width: 20px;
  height: 20px;
  background-color: red;
}

.confirm-prompt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  color:#000;
  border-radius: 8px;
  text-align: center;
  z-index:9999;
}

.confirm-prompt button {
  margin: 0 10px;
  padding: 10px 20px;
}




/* messages */
.messages-container {
  display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 height: 100vh; /* Center vertically */
 color:#fff;
 background-color: green; /* Optional background color */
}
.radio-button {
  padding: 10px 20px;
  margin: 5px;
  border: 2px solid #ccc;
  background-color: white;
  cursor: pointer;
}

.radio-button.selected {
  background-color: blue; /* Highlight color for selected button */
  color: white;
}

.radio-button:hover {
  background-color: lightgray;
}

/* account */
.account-container {
  display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 height: 100vh; /* Center vertically */
 color:#fff;
 background-color: purple; /* Optional background color */
}