.friends-page {
    padding: 20px;
  }
  
  .search-box {
    margin-bottom: 20px;
  }
  
  .friends-list-container {
    display: flex;
    align-items: center;
    overflow-x: auto;
  }
  
  .friends-list {
    display: flex;
    overflow-x: scroll;
    scroll-behavior: smooth;
  }
  
  .friend-item {
    margin: 0 15px;
    text-align: center;
  }
  
  .friend-profile-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .scroll-left, .scroll-right {
    font-size: 24px;
    cursor: pointer;
  }
  
  .scroll-left:hover, .scroll-right:hover {
    color: #000;
  }
  